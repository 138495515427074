export enum EnumReschedulePickupStatus {
  WAITING_FOR_APPROVAL = 'WAITING FOR APPROVAL',
  APPROVE = 'APPROVED',
  REJECT = 'REJECTED'
}

export enum EnumReschedulePickupType {
  CHANGE_TIME = 'CHANGE TIME',
  CHANGE_TIME_AND_COURIER = 'CHANGE TIME AND COURIER',
  MANUAL_ASSIGN = 'MANUAL ASSIGN'
}

export interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

export interface IStyle {
  minWidth: string
  maxWidth: string
}
