


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ExpandIcon from '@/app/ui/assets/arrow_right.vue'
import CloseIcon from '@/app/ui/assets/close_line_icon.vue'

@Component({
  components: {
    ExpandIcon,
    CloseIcon,
  },
})
export default class ImageViewer extends Vue {
  @Prop({ type: Array, required: true }) data!: string[]
  @Prop({ type: Boolean, required: true }) visible!: boolean
  @Prop({ default: 0 }) private defaultIndexProp!: number

  get imageUrls() {
    return this.data;
  }

  parameters = {
    index: 0,
    defaultIndex: 0,
  }

  private onPrevious() {
    if (this.parameters.index === 0) return
    this.parameters.index -= 1;
    this.onChangeImage();
  }

  private onNext() {
    if (this.parameters.index === this.imageUrls.length - 1) return;
    this.parameters.index += 1;
    this.onChangeImage();
  }

  private onClickNav(index: number) {
    this.parameters.index = index;
    this.onChangeImage()
  }

  private onChangeImage() {
    const imageRef = this.$refs.imageTag as HTMLImageElement;
    imageRef.src = this.imageUrls[this.parameters.index];
  }

  private onCheckDisable(buttonSide: 'prev' | 'next') {
    // check if there is no image or only one image
    if (this.imageUrls.length === 0 || this.imageUrls.length === 1) {
      return true
    }

    // check if it's the first image and if true disable the prev button
    if (buttonSide === 'prev') {
      if (this.parameters.index === 0) {
        return true
      }
    }

    // check if it's the last image and if true disable the next button
    if (buttonSide === 'next') {
      if (this.parameters.index === this.imageUrls.length - 1) {
        return true
      }
    }

    return false
  }

  @Watch('visible')
  onVisibleChange(): void {
    if (this.visible) {
      window.document.body.style.overflow = 'hidden'
    } else {
      window.document.body.style.overflow = 'auto'
    }
  }

  @Watch('defaultIndexProp')
  onDefaultIndexProp(value: number): void {
    this.parameters.defaultIndex = value
    this.parameters.index = value
  }
}
