import { ReschedulePickupRequestInterface, UpdateReschedulePickupRequestInterface } from "../contracts/ReschedulePickupRequest";

export class ReschedulePickupRequest implements ReschedulePickupRequestInterface {
  public shipmentIds: string[]

  constructor(shipmentIds: string[]) {
    this.shipmentIds = shipmentIds
  }

  toPayload(): string {
    const payload = {
      shipment_ids: this.shipmentIds
    }
      return JSON.stringify(payload)
  }
}

export class UpdateReschedulePickupRequest implements UpdateReschedulePickupRequestInterface {
  public shipmentId?: string
  public courierId?: number
  public rescheduledAt?: string
  public timeZone?: string

  constructor(
    shipmentId?: string,
    courierId?: number,
    rescheduledAt?: string,
    timeZone?: string
  ) {
    this.shipmentId = shipmentId
    this.courierId = courierId
    this.rescheduledAt = rescheduledAt
    this.timeZone = timeZone
  }

  toPayload(): string {
    const payload = {
      shipment_id: this.shipmentId,
      courier_id: this.courierId,
      rescheduled_at: this.rescheduledAt,
      time_zone: this.timeZone
    }

    return JSON.stringify(payload)
  }
}
