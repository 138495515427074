import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import Vue from 'vue'
import { ReschedulePickupPresenter } from "../presenters/ReschedulePickupPresenter";
import store from "@/app/ui/store";
import { Utils } from "@/app/infrastructures/misc";
import {
  ReschedulePickup,
  ReschedulePickupDetail,
  ReschedulePickups,
  SubDistrict,
  Village
} from "@/domain/entities/ReschedulePickup";
import { Pagination } from "@/domain/entities/Pagination";
import { ReschedulePickupRequest, UpdateReschedulePickupRequest } from "@/data/payload/api/ReschedulePickupRequest";

interface ReschedulePickupControllerInterface {
  isLoading: boolean
  isLOadingVillage: boolean
  dataSubDistricts: SubDistrict
  dataVillages: Village
  dataReschedulePickups: ReschedulePickup[]
  pagination: Pagination
  errorRequestApprove: string
  errorRequestSchedule: string
  dataReschedulePickup: ReschedulePickupDetail
  isApproved: boolean
  requestSchedule: boolean
  totalStatusWaiting: number
}

interface ParametersVillages {
  name: string,
  sub_district: string | number
}

export interface ReschedulePickupPayloadInterface {
  shipmentId?: string
  courierId?: number
  rescheduledAt?: string
  timeZone?: string
}

@Module({
  namespaced: true,
  store,
  name: 'ReschedulePickupController',
  dynamic: true
})
export class ReschedulePickupController extends VuexModule implements ReschedulePickupControllerInterface {
  private presenter: ReschedulePickupPresenter = container.resolve(ReschedulePickupPresenter)
  public isLoading = false
  public isLOadingVillage = false
  public dataSubDistricts = new SubDistrict()
  public dataReschedulePickups = [new ReschedulePickup()]
  public dataVillages = new Village()
  public pagination = new Pagination()
  public errorRequestApprove = ''
  public errorRequestSchedule = ''
  public dataReschedulePickup = new ReschedulePickupDetail()
  public isApproved = false
  public requestSchedule = false
  public totalStatusWaiting = 0

  @Action({ rawError: true })
  public getSubDistrict(): void {
    this.setLoading(true)

    this.presenter.getSubDistrict()
      .then(res => {
        this.setDataSubDistricts(res)
      })
      .catch(error => {
        const errorMessage = error.status === 400 || error.status === 422
          ? error.error.message.en
          : 'Something wrong'
        Vue.notify({
          title: 'Failed Get list Sub Districts',
          text: errorMessage,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => this.setLoading(false))
  }

  @Action({ rawError: true })
  public getVillage(params: ParametersVillages): void {
    this.setLoadingVillage(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter.getVillage(formattedParams)
      .then(res => {
        this.setDataVillages(res)
      })
      .catch(error => {
        const errorMessage = error.status === 400 || error.status === 422
          ? error.error.message.en
          : 'Something wrong'
        Vue.notify({
          title: 'Failed Get list villages',
          text: errorMessage,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => this.setLoadingVillage(false))
  }

  @Action({ rawError: true })
  public getAll(params: Record<string, string | number | Date>): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter.getAll(formattedParams)
      .then(res => {
        this.setDataReschedulePickups(res)
      })
      .catch(error => {
        const errorMessage = error.status === 400 || error.status === 422
          ? error.error.message.en
          : 'Something wrong'
        Vue.notify({
          title: 'Failed Get list Reschedule Pickups',
          text: errorMessage,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => this.setLoading(false))
  }

  @Action({ rawError: true })
  public requestApprove(ids: string[]) {
    this.setLoading(true)
    const payload = new ReschedulePickupRequest(ids)
    this.presenter.postRequestApprove(payload)
      .then(() => {
        this.setIsApproved(true)
      })
      .catch(error => {
        const errorMessage = error.status === 400 || error.status === 422
          ? error.error.message.en
          : 'Something wrong'
        this.setErrorRequestApprove(errorMessage)
        Vue.notify({
          title: 'Request Approve Reschedule Pickup',
          text: errorMessage,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => this.setLoading(false))
  }

  @Action({ rawError: true })
  public get(id: string) {
    this.setLoading(true)
    this.presenter.get(id)
      .then((res) => {
        this.setDataReschedulePickup(res)
      })
      .catch(error => {
        const errorMessage = error.status === 400 || error.status === 422
          ? error.error.message.en
          : 'Something wrong'
        Vue.notify({
          title: 'Failed Get Detail Reschedule Pickup',
          text: errorMessage,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => this.setLoading(false))
  }

  @Action({ rawError: true })
  public update(payload: ReschedulePickupPayloadInterface) {
    this.setLoading(true)
    this.presenter.update(
      new UpdateReschedulePickupRequest(
        payload.shipmentId,
        payload.courierId,
        payload.rescheduledAt,
        payload.timeZone
      )
    )
      .then(() => {
        this.setRequestSchedule(true)
      })
      .catch(error => {
        const errorMessage = error.status === 400 || error.status === 422
          ? error.error.message.en
          : 'Something wrong'
        this.setErrorRequestSechedulePickup(errorMessage)
        Vue.notify({
          title: 'Failed Post Request Reschedule Pickup',
          text: errorMessage,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => this.setLoading(false))
  }

  @Action({ rawError: true })
  public getCounter(): void {
    this.presenter.getCounter()
    .then(res => {
      this.setTotalCounter(res)
    })
    .catch(error => {
      const errorMessage = error.status === 400 || error.status === 422
        ? error.error.message.en
        : 'Something wrong'
      Vue.notify({
        title: 'Failed Get Total Waiting',
        text: errorMessage,
        type: 'error',
        duration: 5000,
      })
    })
  }

  @Mutation
  public setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingVillage(bool: boolean): void {
    this.isLOadingVillage = bool
  }

  @Mutation
  private setDataSubDistricts(data: SubDistrict): void {
    this.dataSubDistricts = data
  }

  @Mutation
  private setDataReschedulePickups(data: ReschedulePickups): void {
    this.dataReschedulePickups = <ReschedulePickup[]>data.data
    this.pagination = <Pagination>data.pagination
  }

  @Mutation
  private setDataReschedulePickup(data: ReschedulePickupDetail): void {
    this.dataReschedulePickup = data
  }

  @Mutation
  private setDataVillages(data: Village) {
    this.dataVillages = data
  }

  @Mutation
  private setErrorRequestApprove(message: string): void {
    this.errorRequestApprove = message
  }

  @Mutation
  private setErrorRequestSechedulePickup(message: string): void {
    this.errorRequestSchedule = message
  }

  @Mutation
  public setIsApproved(bool: boolean): void {
    this.isApproved = bool
  }

  @Mutation
  public setRequestSchedule(bool: boolean): void {
    this.requestSchedule = bool
  }

  @Mutation
  public setTotalCounter(num: number): void {
    this.totalStatusWaiting = num
  }
}

export default getModule(ReschedulePickupController)
