var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"viewer-canvas"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"flex justify-end"},[_c('div',{staticClass:"p-10 cursor-pointer hover:bg-black hover:bg-opacity-10",on:{"click":function($event){return _vm.$emit('close')}}},[_c('CloseIcon',{attrs:{"fill":"white","width":"32","height":"32"}})],1)])]),_c('div',{staticClass:"image-container"},[_c('div',{staticClass:"flex justify-between h-screen items-center gap-12"},[_c('button',{class:[
          'button-style',
          {
            disabled: _vm.onCheckDisable('prev')
          }
        ],on:{"click":function () { return _vm.onPrevious(); }}},[_c('ExpandIcon',{staticClass:"transform rotate-180",attrs:{"width":"32","height":"32"}})],1),(_vm.imageUrls.length === 0)?_c('div',{staticClass:"text-white text-3xl"},[_c('span',[_vm._v("No image")])]):_c('div',{staticClass:"flex justify-center h-5/6"},[_c('img',{ref:"imageTag",staticClass:"object-contain",attrs:{"src":_vm.imageUrls[_vm.parameters.defaultIndex]}})]),_c('button',{class:[
          'button-style',
          {
            disabled: _vm.onCheckDisable('next')
          } ],on:{"click":function () { return _vm.onNext(); }}},[_c('ExpandIcon',{attrs:{"width":"32","height":"32"}})],1)])]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"flex justify-center items-center py-6 gap-2"},_vm._l((_vm.imageUrls),function(item,index){return _c('div',{key:index,class:['image-nav', { selected: index === _vm.parameters.index }],on:{"click":function () { return _vm.onClickNav(index); }}})}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }