




















































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import IconPeople from '@/app/ui/assets/menu_icon_user_management.vue'
import IconCourier from '@/app/ui/assets/icon_motorcycle_front.vue'
import IconPhone from '@/app/ui/assets/icon_phone.vue'
import IconMap from '@/app/ui/assets/menu_icon_pickup_location.vue'
import IconCancelTask from '@/app/ui/assets/icon_cancel_task.vue'
import IconClipboard from '@/app/ui/assets/icon_clipboard.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import IconWarning from '@/app/ui/assets/icon_warning_circle.vue'
import IconClock from '@/app/ui/assets/icon_clock_fill.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import ImageViewer from '@/app/ui/components/ImageViewer/index.vue'
import ModalSuccess from '../../components/Modals/ModalSuccess.vue'
import ModalConfirmation from '../../components/Modals/ModalConfirmation.vue'
import { EnumActions } from '@/app/infrastructures/misc/Constants/pickup'
import controller from '@/app/ui/controllers/ReschedulePickupController'
import {
  ReschedulePickupCourier,
  ReschedulePickupDetail,
} from '@/domain/entities/ReschedulePickup'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { EnumReschedulePickupStatus } from '@/app/infrastructures/misc/Constants/reschedulePickup'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import { Utils } from '@/app/infrastructures/misc'
import IconLocationPin from '@/app/ui/assets/ics_f_location_pin_alt.vue'

@Component({
  components: {
    IconPeople,
    IconCourier,
    IconPhone,
    IconMap,
    IconCancelTask,
    IconClipboard,
    DropdownSelect,
    IconWarning,
    IconClock,
    DateTimePicker,
    Button,
    ImageViewer,
    ModalSuccess,
    ModalConfirmation,
    LoadingOverlay,
    ModalAction,
    IconLocationPin
  },
})
export default class RescheduleDetail extends Vue {
  controller = controller
  EnumActions = EnumActions
  EnumReschedulePickupStatus = EnumReschedulePickupStatus
  showImage = false
  imageSrc = ''
  dataReschedulePickup: ReschedulePickupDetail = new ReschedulePickupDetail()
  lastCourier: ReschedulePickupCourier = new ReschedulePickupCourier()
  assignmentCourier: ReschedulePickupCourier = new ReschedulePickupCourier()
  indexOfImage = 0
  invoiceImage: string[] = []
  showModal = {
    confirm: false,
    success: false,
    connection: false,
  }

  created(): void {
    controller.get(this.$route.params.rescheduleId)
  }

  mounted(): void {
    window.addEventListener('offline', this.onHandleOffline)
  }

  get formattedSchedule() {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(
        <string>this.dataReschedulePickup.rescheduledAt,
        'DD MMM YYYY HH:mm Z',
      ),
    )
  }

  private onHandleOffline(): void {
    if (controller.isLoading) {
      controller.setLoading(false)
      this.showModal.confirm = false
      this.showModal.connection = true
    }
  }

  private onShowImage(index: number): void {
    this.imageSrc = this.invoiceImage[index]
    this.showImage = true
    this.indexOfImage = index
  }

  private openMaps(lat: string, long: string): void {
    window.open(`https://maps.google.com?q=${lat},${long}`, '_blank')?.focus()
  }

  private onActionModal(type: string): void {
    this.showModal.confirm = false
    if (type === EnumActions.SUBMIT) {
      controller.requestApprove([this.$route.params.rescheduleId])
    }
  }

  @Watch('controller.dataReschedulePickup')
  onWatchDataReschedulePickup(data: ReschedulePickupDetail): void {
    this.dataReschedulePickup = data
    this.lastCourier = <ReschedulePickupCourier>data.lastCourier
    this.assignmentCourier = <ReschedulePickupCourier>data.assignCourier

    if (!data.meta?.proofs) {
      return
    } else if (data.meta?.proofs && data.meta?.proofs.length <= 4) {
      this.invoiceImage = data.meta.proofs
    } else {
      this.invoiceImage = data.meta.proofs.filter((_, index) => index < 4)
    }
  }

  @Watch('controller.isApproved')
  onWatchErrorRequest(value: boolean): void {
    if (value) {
      this.showModal.success = true
      controller.setIsApproved(false)
    }
  }

  beforeDestroy(): void {
    window.removeEventListener('offline', this.onHandleOffline)
  }
}
